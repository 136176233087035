import { Component, Vue } from 'vue-property-decorator';
import {
	CONTEXT_CONFIGURATIONS_ITEM,
	STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM,
	STORAGE_TO_TABLES_CONFIGURATIONS_ITEM,
	TABLE_TO_STORAGE_CONFIGURATIONS_ITEM,
	TABLES_TO_TABLES_CONFIGURATIONS_ITEM,
	VM_LAUNCHER_CONFIGURATIONS_ITEM,
	WORKFLOW_CONFIGURATIONS_ITEM,
	XML_CONVERSION_CONFIGURATIONS_ITEM,
} from '@/constants/router/routes-names';

@Component
export default class ConfigurationRouteName extends Vue {
	// TODO: Make common with "src/views/data-models/TableDetails.vue"
	// (Added `tables-to-tables` only here
	getConfigurationRouteName(configurationType: string) {
		switch (configurationType) {
			case 'storage-to-storage':
				return STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM;
			case 'workflow':
				return WORKFLOW_CONFIGURATIONS_ITEM;
			case 'vm-launcher':
				return VM_LAUNCHER_CONFIGURATIONS_ITEM;
			case 'storage-to-tables':
				return STORAGE_TO_TABLES_CONFIGURATIONS_ITEM;
			case 'table-to-storage':
				return TABLE_TO_STORAGE_CONFIGURATIONS_ITEM;
			case 'tables-to-tables':
				return TABLES_TO_TABLES_CONFIGURATIONS_ITEM;
			case 'context':
				return CONTEXT_CONFIGURATIONS_ITEM;
			case 'xml-conversion':
				return XML_CONVERSION_CONFIGURATIONS_ITEM;
			case 'api-to-storage':
				return ''; // TODO: Set route
			default:
				return '';
		}
	}
}
