import { Component, Vue } from 'vue-property-decorator';
import { ACTIVITIES_OVERVIEW } from '@/constants/data-operations/names';
import {
	ACTIVITIES_OVERVIEW_CONFIGURATIONS_LISTING,
	ACTIVITIES_OVERVIEW_JOBS_LISTING,
	ACTIVITIES_OVERVIEW_RUNS_LISTING,
} from '@/constants/router/routes-names';

@Component
export default class HeaderInfos extends Vue {
	get workflowName() {
		return ACTIVITIES_OVERVIEW.displayName;
	}

	get tabsItems() {
		return [
			{ id: 1, title: 'Runs', link: { name: ACTIVITIES_OVERVIEW_RUNS_LISTING } },
			{ id: 2, title: 'Jobs', link: { name: ACTIVITIES_OVERVIEW_JOBS_LISTING } },
			{ id: 3, title: 'Confs', link: { name: ACTIVITIES_OVERVIEW_CONFIGURATIONS_LISTING } },
		];
	}
}
