import { collectionGroup, getFirestore, query, where, getDocs, orderBy } from 'firebase/firestore';
import { round } from 'lodash';
import { State } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import ConfigurationRouteName from '@/mixins/common/configuration-route-name';
import { AnyObject, EnvFilter, RunStatusFilter } from '@/types';
import { ConfigurationTypes } from '@/constants/data-operations/configuration-types';
import {
	STORAGE_TO_STORAGE_RUNS_ITEM,
	STORAGE_TO_TABLES_RUNS_ITEM,
	TABLE_TO_STORAGE_RUNS_ITEM,
	TABLES_TO_TABLES_RUNS_ITEM,
	VM_LAUNCHER_RUNS_ITEM,
	WORKFLOW_STATUS_ITEM,
} from '@/constants/router/routes-names';
import { Environments, Statuses } from '@/constants/data-operations/filters';

@Component
export default class ActivitiesOverviewMixin extends Mixins(ConfigurationRouteName) {
	@State((state) => state.filters.runStatusFilterSelected) runStatusFilterSelected!: RunStatusFilter;
	@State((state) => state.filters.envFilterSelected) envFilterSelected!: EnvFilter;
	@State((state) => state.filters.minDateFilter) minDateFilter!: string;

	configurationType = (this.$route.query.configuration_type as string) ?? this.configurationTypes[0].value;

	async getTailerActivitiesRuns() {
		let runsQueryConstraints = [
			where('collection_group_name', '==', 'tailer-activities-runs/account/configuration_type/configuration_id/runs'),
			where('account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id),
			where('execution_date_utc', '>=', new Date(this.minDateFilter)),
			orderBy('execution_date_utc', 'desc'),
		];

		if (this.envFilterSelected.value !== Environments.All)
			runsQueryConstraints.push(where('environment', '==', this.envFilterSelected.value));

		if (this.runStatusFilterSelected.value !== Statuses.All)
			runsQueryConstraints.push(where('status', '==', this.runStatusFilterSelected.value));

		// TODO: Add const
		if (this.configurationType !== 'ALL')
			runsQueryConstraints.push(where('configuration_type', '==', this.configurationType));

		const runs = query(collectionGroup(getFirestore(), 'runs'), ...runsQueryConstraints);
		const querySnapshot = await getDocs(runs);
		const items: AnyObject[] = [];

		querySnapshot.forEach((doc) => items.push({ ...doc.data(), id: doc.id }));
		return items;
	}

	// TODO: Make common with "src/views/data-models/TableDetails.vue"
	// (Added `tables-to-tables` only here
	getRunRouteName(configurationType: string) {
		switch (configurationType) {
			case 'storage-to-storage':
				return STORAGE_TO_STORAGE_RUNS_ITEM;
			case 'workflow':
				return WORKFLOW_STATUS_ITEM;
			case 'vm-launcher':
				return VM_LAUNCHER_RUNS_ITEM;
			case 'storage-to-tables':
				return STORAGE_TO_TABLES_RUNS_ITEM;
			case 'table-to-storage':
				return TABLE_TO_STORAGE_RUNS_ITEM;
			case 'tables-to-tables':
				return TABLES_TO_TABLES_RUNS_ITEM;
			case 'context':
				return '';
			case 'xml-conversion':
				return '';
			case 'api-to-storage':
				return '';
			default:
				return '';
		}
	}

	getFormattedDuration(duration: number) {
		return `${round(duration, 1)}s`;
	}

	get configurationTypes() {
		return [
			{ label: 'All confs', value: ConfigurationTypes.All },
			{ label: 'Api To Storage', value: ConfigurationTypes.ApiToStorage },
			{ label: 'Storage To Storage', value: ConfigurationTypes.StorageToStorage },
			{ label: 'Storage To Tables', value: ConfigurationTypes.StorageToTables },
			{ label: 'Tables To Tables', value: ConfigurationTypes.TablesToTables },
			{ label: 'Table To Storage', value: ConfigurationTypes.TableToStorage },
			{ label: 'Vm Launcher', value: ConfigurationTypes.VMLauncher },
		];
	}

	get selectedConfigurationTypeLabel() {
		return this.configurationTypes.find((configurationType) => configurationType.value === this.configurationType)!
			.label;
	}
}
